<template>
    <div class="right_main">
        <!-- 按钮部分 -->
        <div class="btn_cl" v-if="btn_show">
            <el-button class="add_btn" @click="addtion">添加</el-button>
            <el-button @click="deltion">删除</el-button>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="合作方" name="first">
                <!--表格部分  -->
                <div class="tab_list">
                    <el-table ref="multipleTable" @selection-change="handleSelectionChange" :data="tableData"
                        style="width: 100%" :header-cell-style="{color:'#000000',font:'14px 600',borderBottom:'none'}">
                        <el-table-column type="selection" align="center">
                        </el-table-column>
                        <el-table-column prop="uid" v-if="show=false">
                        </el-table-column>
                        <el-table-column prop="nickname" label="用户名">
                        </el-table-column>
                        <el-table-column prop="partner_uid" label="公司名称">
                        </el-table-column>
                        <el-table-column prop="auth_id" label="权限">
                        </el-table-column>
                        <el-table-column prop="username" label="登陆账号">
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号">
                        </el-table-column>
                        <el-table-column prop="status" label="状态">
                        </el-table-column>
                        <el-table-column label="设置">
                            <template slot-scope="scope">
                                <span style="margin-right:16px" @click="handle_edit(scope.row.uid)">编辑</span>
                                <span @click="handle_del(scope.row.uid)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <div v-if="part_show">
                    <Paging :count="count" :initData="partner_init" @changePage="handle_changePage"></Paging>
                </div>
            </el-tab-pane>
            <el-tab-pane label="admin" name="second">
                <!--表格部分  -->
                <div class="tab_list">
                    <el-table ref="multipleTable" @selection-change="handleSelectionChange" :data="tableData"
                        style="width: 100%"
                        :header-cell-style="{color:'#000000',textAlign:'center',font:'14px 600',borderBottom:'none'}">
                        <el-table-column type="selection" align="center">
                        </el-table-column>
                        <el-table-column prop="uid" align="center" v-if="show=false">
                        </el-table-column>
                        <el-table-column prop="nickname" label="用户名" align="center">
                        </el-table-column>
                        <el-table-column prop="username" label="登陆账号" align="center">
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号" align="center">
                        </el-table-column>
                        <el-table-column prop="auth_id" label="权限" align="center">
                        </el-table-column>
                        <el-table-column prop="status" label="状态" align="center">
                        </el-table-column>
                        <el-table-column label="设置" align="center">
                            <template slot-scope="scope">
                                <span style="margin-right:16px" @click="handle_edit(scope.row.uid)">编辑</span>
                                <span @click="handle_del(scope.row.uid)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <div v-if="admin_show">
                    <Paging :count="count" :initData="admin_init" @changePage="handle_changePage"></Paging>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <span>删除</span>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
                <el-button class="del_confirm" @click="Subimt_del">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getPartRoleApi, deletPartRoleApi, getAdminApi, deletAdminApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 删除弹框显示
            del_dialog: false,
            activeName: 'first',
            btn_show: true,
            // 删除提示语
            del_tip: '',
            // 删除数据数组
            del_arr: [],
            // 表格数据
            tableData: [],
            multipleSelection: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            part_show: false,
            admin_show: false,
        }
    },
    created () {
        this.partner_init()
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.activeName == 'first') {
                if (this.count == (this.page - 1) * this.max && this.count != 0) {
                    this.page -= 1;
                    this.partner_init()
                }
            } else {
                if (this.count == (this.page - 1) * this.max && this.count != 0) {
                    this.page -= 1;
                    this.admin_init()
                }
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 合作方初始化数据
        partner_init () {
            getPartRoleApi({
                page: this.page
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    if (this.count <= 10) {
                        this.part_show = false;
                    } else {
                        this.part_show = true;
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        },
        // admin初始化数据
        admin_init () {
            getAdminApi({
                page: this.page
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    if (this.count <= 10) {
                        this.admin_show = false;
                    } else {
                        this.admin_show = true;
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        // 弹窗关闭
        handleClose () {
            this.del_dialog = false;
        },
        // 选项卡点击
        handleClick () {
            if (this.activeName == 'first') {
                this.partner_init();
            } else {
                this.admin_init();
            }
        },
        // 点击添加按钮
        addtion () {
            if (this.activeName == 'first') {
                this.$router.push({ path: '/roleadd', query: { tip: '合作方添加' } })
            } else {
                this.$router.push({ path: '/roleadd', query: { tip: 'admin添加' } })
            }

        },
        // 点击编辑按钮
        handle_edit (id) {
            if (this.activeName == 'first') {
                this.$router.push({ path: '/roleadd', query: { tip: '合作方编辑', id: id } })
            } else {
                this.$router.push({ path: '/roleadd', query: { tip: 'admin编辑', id: id } })
            }
        },
        // 单个删除按钮点击
        handle_del (id) {
            this.del_dialog = true;
            this.btn_show = false;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除按钮点击
        deltion () {
            this.del_dialog = true;
            this.btn_show = false;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.multipleSelection.length; i++) {
                let del_id = this.multipleSelection[i].uid;
                this.del_arr.push(del_id);
            };
        },
        // 删除弹窗关闭  清空数组
        remove_Fn () {
            this.del_dialog = false;
            this.btn_show = true;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除弹框点击确定按钮
        Subimt_del () {
            if (this.activeName == 'first') {
                deletPartRoleApi({
                    id: this.del_arr,
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('删除成功');
                        this.del_dialog = false;
                        this.partner_init();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else {
                deletAdminApi({
                    id: this.del_arr,
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('删除成功');
                        this.del_dialog = false;
                        this.admin_init();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
    },
}
  </script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    position: relative;
    // 选项卡
    .el-tabs {
        /deep/.el-tabs__active-bar {
            display: none;
        }
        /deep/.el-tabs__item {
            width: 80px;
            height: 38px;
            text-align: center;
            color: #20252b;
            font-size: 16px;
            font-weight: 600;
            background: #eaf0fe;
            // border-radius: 8px;
        }
        /deep/.el-tabs__item.is-active {
            width: 80px;
            height: 38px;
            // text-align: center;
            border-radius: 8px;
            background: #6a8be8;
            color: #ffffff;
            font-weight: 600;
        }
        /deep/#tab-first {
            // text-align: right;
            padding-left: 16px;
            border-radius: 8px 0 0 8px;
        }
        /deep/#tab-second {
            // text-align: left;
            padding-right: 16px;
            border-radius: 0 8px 8px 0;
        }
        /deep/.el-tabs__nav-wrap::after {
            position: static !important;
        }
        /deep/.el-tabs__header {
            padding: 24px 24px 0;
        }
        // 表格部分
        .tab_list {
            height: 540px;
            background: #ffffff;
            border-radius: 0 0 20px 20px;
            padding: 20px 40px;
            .el-table {
                border-bottom: none;
            }
            /deep/.el-table td,
            .el-table th.is-leaf {
                border-bottom: none;
            }
            /deep/.el-table--border::after,
            .el-table--group::after,
            .el-table::before {
                background: #ffffff;
            }
            border-top: 1px solid #e8e9f1;
            // 操作按钮
            span {
                cursor: pointer;
            }
        }
    }
    // 按钮部分
    .btn_cl {
        z-index: 99999;
        position: absolute;
        right: 20px;
        top: 20px;
        .el-button {
            width: 76px;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            border-radius: 6px;
            border: none;
            background: #eaf0fe;
        }
    }
    // 删除弹框
    .el-dialog {
        text-align: center;
        // 标题
        .tipes {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            span {
                border-left: 4px solid #6a8be8;
                padding-left: 6px;
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 50px 0;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 160px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
                border-radius: 6px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-left: 8px;
                background: #6a8be8;
                border: none;
                color: #ffffff;
            }
            .del_Reset {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                color: #20252b;
            }
        }
    }
}
</style>